<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
     <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>공지사항</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">SUPPORT</a></li>
                        <li><a href="#">공지사항</a></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section notice">
            <div class="innWrap board-list">
                <div class="lineBox">
                    <div class="searchBox-select">
                        <div class="select select-inner w288px" id="notice-01">
                            <span class="placeholder"><span class="fcGy">제목</span></span>
                            <div class="list">
                                <ul>
                                    <li v-for="item in type" :key="item.value" @click="selectValue('type', item.value)" :value="item.value">{{item.title}}</li>
                                </ul>
                            </div>
                        </div>
                        <input type="text" id="notice-02" placeholder="[TEST] 시스템 배포 작업 공지_01" class="input-line" v-model="searchWord">
                        <a class="btn-md01 btn-primary" @click="getNoticeInfo()">검색</a>
                    </div>
                </div>
                <table class="tbl_type02 mgt40">
                    <colgroup>
                        <col style="width: 120px;">
                        <col style="">
                        <col style="width: 188px;">
                    </colgroup>
                    <tbody>
                        <tr v-for="item in response" :key="item.disp_order">
                            <td>{{item.disp_order}}</td>
                            <td class="agl" @click="goDetail(item.seq)">{{item.notice_title}}</td>
                            <td>{{item.reg_dt}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div class="pagination">
                    <div class="scope">
                        <a href="javascript:;" class="dir first disabled">첫 페이지로 이동</a>
                        <a href="javascript:;" class="dir prev disabled">이전 페이지로 이동</a>
                        <span title="현재위치">1</span>
                        <a href="javascript:;">2</a>
                        <a href="javascript:;">3</a>
                        <a href="javascript:;" class="dir next">다음 페이지로 이동</a>
                        <a href="javascript:;" class="dir last">마지막 페이지로 이동</a>
                    </div>
                </div> -->
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      response: '',
      type: [
          {title: '제목', value: 'title'},
          {title: '내용', value: 'content'}
      ],
      searchType : 'title',
      searchWord : '',
    }
  },
  created: function () {

  },
  mounted: async function () {
    await this.getNoticeInfo()
  },
  methods: {
    selectValue(type, value) {
        this.searchType = value;
    },
    goDetail (seq) {
      this.$router.push({ name: 'goSupNot001' ,params: {seq: seq}})
    },
    async getNoticeInfo() {
      let title = ''
      let content = ''
      this.searchType === 'title' ? title = this.searchWord : content = this.searchWord

      let payload = {
        notice_title : title,
        notice_detail_info : content
      }

      await this.$store.dispatch('gigaoffice/getNoticeInfo', payload).then(res => {
				this.response = res.data.response.results
			})
			.catch(err => {
				console.log(err)
			})
    }
  }
}
</script>